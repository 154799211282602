import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

import SEO from 'components/core/SEO'

import { setState } from 'store/store'

import Layout from 'components/ui/Layout'
import SliceZone from 'components/ui/SliceZone'

import { PageTypes } from './PageTypes'

import './Page.scss'

const Page = ({ data }: PageTypes) => {
  const slices = data.prismicPage.data.body
  const footer = {
    info1: data.prismicPage.data.info_1,
    info2: data.prismicPage.data.info_2,
    info3: data.prismicPage.data.info_3,
    author: data.prismicPage.data.author,
    quote: data.prismicPage.data.quote,
    theme: 'Ice',
  }
  const nextPage = data.prismicPage.data.next_page

  useEffect(() => {
    setState({ customHeader: data.prismicPage.uid !== 'chronology' })
    return () => setState({ customHeader: false })
  }, [data])

  return (
    <>
      <Helmet>{data.prismicPage.uid === 'chronology' ? <html data-bg-blue /> : <html data-bg-grey />}</Helmet>
      <SEO />
      <Layout
        data-page={data.prismicPage.uid}
        footerData={!Object.values(footer).some(x => x == null) ? footer : null}
        nextPage={nextPage || null}
      >
        <SliceZone slices={slices} page='page' />
      </Layout>
    </>
  )
}

export default Page
