import { graphql } from 'gatsby'

import Page from 'views/Page'

export const query = graphql`
  query PageQuery($uid: String) {
    prismicPage(uid: { eq: $uid }) {
      uid
      data {
        info_1
        info_2
        info_3
        quote {
          raw
        }
        author {
          raw
        }
        next_page {
          document {
            ... on PrismicPage {
              id
              uid
              type
              href
            }
          }
          slug
        }
        body {
          ... on PrismicPageBodySectionTitle {
            id
            slice_type
            primary {
              background_color
              description {
                raw
              }
              title {
                raw
              }
            }
          }
          ... on PrismicPageBodySplitSections {
            id
            slice_type
            primary {
              background_color
              add_bottom_padding
            }
            items {
              desktop_layout
              mobile_layout
              text_alignment
              background_color
              background_image {
                localFile {
                  childImageSharp {
                    fluid(quality: 90) {
                      aspectRatio
                      base64
                      src
                      srcWebp
                    }
                  }
                }
                alt
              }
              title {
                raw
              }
              text {
                raw
              }
              captions_desktop {
                raw
              }
              captions_mobile {
                raw
              }
              content_image {
                localFile {
                  childImageSharp {
                    fluid(quality: 90) {
                      aspectRatio
                      base64
                      src
                      srcWebp
                      srcSet
                      srcSetWebp
                    }
                    original {
                      height
                      width
                    }
                  }
                }
                alt
              }
              parallax_speed
            }
          }
          ... on PrismicPageBodyNarrowImage {
            id
            slice_type
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 90) {
                      #   ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                      base64
                      src
                      srcWebp
                      srcSet
                      srcSetWebp
                    }
                    original {
                      height
                      width
                    }
                  }
                }
                alt
              }
              background_color
            }
          }
          ... on PrismicPageBodyFullWidthImage {
            id
            slice_type
            primary {
              background_color
              captions {
                raw
              }
              captions_mobile {
                raw
              }
              description {
                raw
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 90) {
                      aspectRatio
                      base64
                      srcWebp
                      src
                      srcWebp
                      srcSet
                      srcSetWebp
                    }
                    original {
                      height
                      width
                    }
                  }
                }
                alt
              }
            }
          }
          ... on PrismicPageBodyChronology {
            id
            slice_type
            primary {
              description {
                raw
              }
              event {
                raw
              }
              year
            }
          }
        }
      }
    }
  }
`

export default Page
